import api from './api'

export default {
    criarSistema(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/sistema/upinsert', body, config)
    },
    buscar(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/sistema/select', body, config)
    }
}