import api from './api'
// import axios from 'axios'

export default {
  criar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/cliente/upinsert', body, config)
  },
  buscar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    // alert(JSON.stringify(body,null,2))
    return api().post('/cliente/select', body, config)
  },
  buscarParametros(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    // alert(JSON.stringify(body,null,2))
    return api().post('/cliente/buscarParametros', body, config)
  },
  desativarCliente(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    // alert(JSON.stringify(body,null,2))
    return api().post('/cliente/desativarCliente', body, config)
  },
  salvarParametro(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    // alert(JSON.stringify(body,null,2))
    return api().post('/cliente/salvarParametro', body, config)
  },
  PausarCliente(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    // alert(JSON.stringify(body,null,2))
    return api().post('/cliente/pausarCliente', body, config)
  },
  buscarUtilizacao(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/cliente/buscarUtilizacao', body, config)
  },
  buscarRelatorioMensal(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    console.log(body)
    return api().post(body.url,body, config)
    //return axios.get(`http://localhost:3049/dashboard/relatorio/mensal`, config)
  },
  gerarRelatorioUtilizacaoFicha(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    console.log(body)
    return api().post(body.url,body, config)
    //return axios.get(`http://localhost:3049/dashboard/relatorio/mensal`, config)
  }
}