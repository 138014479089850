import api from './api'

export default {
  login(body) {
        return api().post("/auth/login", body).then(resp => {
          let token = resp.data.token
                let usuario = resp.data
                localStorage.setItem("tktaskcheck", token)
                localStorage.setItem("idusuario", usuario.id)
                localStorage.setItem("email", usuario.email)
                localStorage.setItem("nome", usuario.nome)
                localStorage.setItem("tipousuario", usuario.tipo_usuario)
                return resp
      }).catch(e => {
        console.log('error login', e)
      })
  },
  logout() {
    localStorage.removeItem("tktaskcheck")
    localStorage.removeItem("idusuario")
    localStorage.removeItem("email")
    localStorage.removeItem("nome")
    localStorage.clear();
    window.location.href="/login";
  },
  recuperarSenha(body){
    return api().post('/auth/recuperarsenha', body)
  },
  redefinirSenha(body){
    return api().post('/auth/redefinirsenha', body)
  }
}