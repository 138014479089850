<template>
  <div class="card-skeleton">
    <div class="card-header">
      <span class="title-skeleton animation"></span>
    </div>
    <ul class="list-skeleton">
      <li v-for="line, idx in [...new Array(lines)]" :key="idx" class="item-skeleton animation">
        {{line}}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data(){
    return{
      lines: 12
    }
  }
}
</script>
<style lang="scss" scoped>

.card-skeleton{
  border-radius: .5em;
  background-color: white;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  padding: 1em;
}
.list-skeleton{
  width: 100%;
}
.item-skeleton{
  border-radius: .5em;
  margin-bottom: 1em;
  height: 1.5em;
  list-style: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.card-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}
.title-skeleton{  
  border-radius: .5em;
  margin-bottom: 1em;
  height: 2em;
  width: 4em;
}


@keyframes skeleton-loading {
  0%{
    background-color: #ddd;
  }
  100%{
    background-color: #eee;
  }
}

.animation{
  -webkit-animation: skeleton-loading .5s infinite alternate;
  animation: skeleton-loading .5s infinite alternate;
}

//css indesejados
.v-application ul, .v-application ol {
    padding-left: unset;
}

</style>