import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueMask from 'v-mask'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import VueSignature from "vue-signature-pad";
import ProgressBar from 'vuejs-progress-bar'
// import VueSocketIO from 'vue-socket.io'
// import SocketIO from "socket.io-client";



import vueToPdf from 'vue-to-pdf';

Vue.use(vueToPdf);
Vue.use(VueSignature);
Vue.use(VueMask);
Vue.use(ProgressBar)

Vue.config.productionTip = false

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md'
})



import { APIURL } from './constantes.js'
import axios from 'axios'
axios.defaults.baseURL = APIURL
axios.defaults.headers.get['Accept'] = 'application/json; charset=utf-8'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const tokenrecuperado = localStorage.getItem('tktaskcheck')
if (tokenrecuperado) {
  axios.defaults.headers.common['x-access-token'] = tokenrecuperado
}


import { VueMaskFilter } from 'v-mask'
Vue.use(VueMask);
Vue.filter('VMask', VueMaskFilter)

import dayjs from 'dayjs'
Vue.filter('formatarData', function(value) {
    if (value) {
        return dayjs(String(value)).format('DD/MM/YYYY')
    }
})

Vue.filter('formatarDataDD', function(value) {
  if (value) {
      return dayjs(String(value)).format('DD/MM/YY')
  }
})

Vue.filter('formatarDataHora', function(value) {
  if (value) {
      // return moment(String(value)).format('DD/MM/YYYY HH:mm')
      return dayjs(String(value)).format('DD/MM/YYYY HH:mm')
  }
})

Vue.filter('formatarMoeda', function(value) {
  if (value) {
      var v = parseFloat(value).toFixed(2);
      v = v.replace(".", ",");
      v = v.replace(/,(\d)\b/g, ",$10");
      v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
      v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
      return v;
  } else {
      return '0,00'
  }
})


// Vue.use(
//   new VueSocketIO({
//       debug: true,
//       // connection: SocketIO(axios.defaults.baseURL+'/fd',{query: { tipo:'CAPTURA_DOCUMENTOS' }, autoConnect: false}),
      
//       connection: SocketIO(axios.defaults.baseURL + '/central', { query: {}, autoConnect: false }),
//       // connection: SocketIO('http://localhost:3343/central', { query: {}, autoConnect: false }),
//       vuex: {
//           actionPrefix: "SOCKET_",
//           mutationPrefix: "SOCKET_",
//       },
//       // options: [{ transports: ["websocket"] }],
//   })
// );

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
