import api from './api'

export default {
    criar(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/upinsert', body, config)
    },
    gerarCronograma(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/gerarCronograma', body, config)
    },
    iniciarImplantacao(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/iniciarImplantacao', body, config)
    },
    removerImpedidoTarefa(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/removerImpedidoTarefa', body, config)
    },
    adicionarImpedidoTarefa(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/adicionarImpedidoTarefa', body, config)
    },
    rodarChecklist(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/rodarchecklist', body, config)
    },
    selecionarAgendamentos(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/selecionarAgendamentos', body, config)
    },
    selecionarAgendamentosNew(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/selecionarAgendamentosNew', body, config)
    },
    selecionarSubTasks(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/selecionarSubTasks', body, config)
    },
    salvarAgendamento(body) {

        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
          console.log('config ',JSON.stringify(config,null,2))
        return api().post('/implantacao/salvarAgendamento', body, config)
    },
    salvarEtapaComoFeito(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/salvarEtapaComoFeito', body, config)
    },
    removerAgendamento(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/removerAgendamento', body, config)
    },
    salvarEtapaComoDesfeito(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/salvarComoDesfeito', body, config)
    },
    desativarImplantacao(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/desativarImplantacao', body, config)
    },
    ativarImplantacao(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/ativarImplantacao', body, config)
    },
    buscar(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/select', body, config)
    },
    buscarImplantacoesQuadro(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/buscarImplantacoesQuadro', body, config)
    },
    buscarCheckList(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/select/checklist', body, config)
    },
    setCheckList(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/implantacao/setCheckList', body, config)
    },
    agendarEvento(body) {
        // alert(1.1)
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
          // alert(1.2)
        return api().post('/implantacao/agendarEvento', body, config)
    },
    buscarFull(body){
      let config = {}
      if('id' in body){
        config = {
          headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
        };
      }
      return api().post('/implantacao/select/full', body, config)
    },
    getAgendaImplantador(body){
      // let config = {}
      // if('id' in body){
      //   config = {
      //     headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      //   };
      // }
      const config = {
        headers: { 'x-access-token': `${body.token}`}
      };
      return api().post('/implantacao/getagendaimplantador', body, config)
    },
    buscarAgendaMarcada(body){
      // let config = {}
      // if('id' in body){
      //   config = {
      //     headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      //   };
      // }

      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
      return api().post('/implantacao/getagendamarcada', body, config)
    },

    requisitarTipoObservacoes(body){
   
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
      return api().post('/implantacao/listarTipoObservacoes', body, config)
    },
    registrarObservacao(body){
    
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
      return api().post('/implantacao/registrarObservacao', body, config)
    },
    listarObservacoes(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
      return api().post('/implantacao/listarObservacoes', body, config)
    },
    buscarImplantacaoCliente(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      };
      return api().post('/implantacao/buscarImplantacao/cliente', body, config)
    }


}